// Dart Sass

/*==============================
foundation
==============================*/
@use "foundation/variables";
@use "foundation/foundation";
@use "foundation/common";
@use "foundation/functions";

/*==============================
layout
==============================*/
@use "layout/layout";

/*==============================
object
==============================*/
@use "object/component";
@use "object/page";

/*==============================
utility
==============================*/
@use "utility/utility";

/*==============================
フォント
==============================*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,600;1,500&display=swap');