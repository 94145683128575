@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/common" as c;

/*==============================
文字種
==============================*/
.c__common_text {
  font-size: clamp(12px, 2vw, 14px);
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(40);
  line-height: f.xd_line_height(14, 26);
  text-align: justify;
  @include c.sp {
    line-height: f.xd_line_height(12, 24);
  }
}
.c__head_description {
  font-size: clamp(12px, 2vw, 14px);
  font-weight: v.$fw_medium;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(40);
  line-height: f.xd_line_height(16, 32);
  text-align: center;
  @include c.sp {
    line-height: f.xd_line_height(14, 28);
  }
}
.c__error_message {
  color: v.$notify_pink;
  font-size: 14px;
  @include c.xd_cropped_line_height(14, 30);
}
.c__text_underline {
  position: relative;
  width: 100%;
  padding-bottom: 0.15em;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #bbb;
  }
}
/*==============================
見出し系
==============================*/
.c__head_primary {
  font-size: 26px;
  font-weight: v.$fw_bold;
  font-family: v.$poppins;
  color: v.$letter_primary;
  text-align: center;
  letter-spacing: f.xd_letter_spacing(40);
  line-height: f.xd_line_height(26, 27);
  @include c.sp {
    font-size: 20px;
    line-height: f.xd_line_height(20, 27);
  }
}
.c__vertical_head {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  display: grid;
  place-items: center;
  margin: 0 auto;
  background-color: v.$pink;
  width: clamp(24px, 10vw, 68px);
  font-family: v.$poppins;
  font-size: 58px;
  font-weight: v.$fw_semibold;
  color: v.$navy;
  line-height: 1;
  padding: 0.2em 0;
  @include c.tab {
    width: clamp(40px, 6vw, 60px);
    font-size: clamp(24px, 4vw, 58px);
  }
}

/*==============================
ボタン系
==============================*/
.c__kirakira_button {
  margin: 0 auto;
  max-width: 222px;
  min-width: 222px;
  height: 48px;
  font-weight: v.$fw_medium;
  font-size: 16px;
  a {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    border-bottom: solid 1px white;
    background: transparent;
    position: relative;
    &:before,
    &:after {
      position: absolute;
      content: "";
      background-image: url(../../img/common/kirakira.svg);
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.3s;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    @include c.mq_hover_device {
      &:hover {
        &:before {
          transform: translateY(-50%) rotate(180deg);
        }
        &:after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
  }
  @include c.sp {
    font-size: 14px;
    max-width: 180px;
    min-width: 180px;
    a {
      &:before,
      &:after {
        width: 10px;
        height: 10px;
      }
    }
  }
}
.c__plus_button {
  display: block;
  width: 60px;
  height: 60px;
  border: v.$border_primary;
  border-radius: 100vh;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 1px;
    background-color: #fff;
    transition: 0.3s;
    transform: translate3d(-46%, 0, 0);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 24px;
    background-color: #fff;
    transition: 0.3s;
    transform: translate3d(0, -46%, 0);
  }
  @include c.mq_hover_device {
    &:hover {
      background-color: v.$white_opacity;
      &:before {
        transform: translate3d(-46%, 0, 0) rotate(90deg) scale(80%);
      }
      &:after {
        transform: translate3d(0, -46%, 0) rotate(90deg) scale(80%);
      }
    }
  }
}
.c__x_button {
  display: block;
  width: 60px;
  height: 60px;
  border: v.$border_primary;
  border-radius: 100vh;
  position: relative;
  background-color: v.$white_opacity;
  cursor: pointer;
  transition: 0.3s;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 1px;
    background-color: #fff;
    transition: 0.3s;
    transform: translateX(-46%) rotate(45deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 24px;
    background-color: #fff;
    transition: 0.3s;
    transform: translateY(-46%) rotate(45deg);
  }
  @include c.mq_hover_device {
    &:hover {
      &:before {
        transform: translateX(-46%) rotate(-45deg) scale(80%);
      }
      &:after {
        transform: translateY(-46%) rotate(-45deg) scale(80%);
      }
    }
  }
  @include c.tab {
    width: clamp(40px, 12vw, 60px);
    height: clamp(40px, 12vw, 60px);
    &:before {
      width: clamp(14px, 6vw, 20px);
    }
    &:after {
      height: clamp(14px, 6vw, 20px);
    }
  }
}
.c__button_underline {
  width: 100%;
  font-family: v.$poppins;
  font-weight: v.$fw_extralight;
  font-size: 20px;
  text-align: center;
  a {
    position: relative;
    width: 98px;
    padding-bottom: 10px;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: v.$letter_primary;
    }
  }
  @include c.tab {
    margin-bottom: 100px;
  }
}
.c__sns_share {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 60px;
  font-size: clamp(14px, 2vw, 18px);
  font-family: v.$poppins; //明示
  font-weight: v.$fw_extralight;
  text-decoration: none;
  color: v.$letter_primary;
  background: transparent;
  border: v.$border_primary;
  transition: 0.3s;
  border-radius: 100vh;
  cursor: pointer;
  .sns_share_text {
    position: relative;
    padding-left: clamp(2em, 2vw, 2.5em);
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-image: url(../../img/header/twitter_wh.svg);
      background-repeat: no-repeat;
      width: 1.3em;
      height: 1.3em;
      position: absolute;
      top: 0.5em;
      left: 0;
      transform: translate(0, -0.3em);
    }
  }
  @include c.mq_hover_device {
    &:hover {
      background-color: v.$white_opacity;
    }
  }
  @include c.sp {
    width: 180px;
  }
}
.c__simple_table {
  .tr {
    display: flex;
  }
}
/*==============================
  装飾系
==============================*/
.c__kirakira_border {
  display: block;
  width: calc(100% - 60px);
  margin: 0 auto;
  border-bottom: 1px solid #fff;
  padding: 0 30px;
  position: relative;
  &:before,
  &:after {
    position: absolute;
    content: "";
    background-image: url(../../img/common/kirakira.svg);
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
  }
  &:before {
    left: -30px;
  }
  &:after {
    right: -30px;
  }
  @include c.sp {
    padding: 0 20px;
    width: calc(100% - 40px);
    &:before,
    &:after {
      width: 10px;
      height: 10px;
    }
    &:before {
      left: -20px;
    }
    &:after {
      right: -20px;
    }
  }
}
.js__transition {
  .c__kirakira_border {
    width: 0;
    padding: 0 0;
    transition: 1.4s ease;
    transition-delay: 0.3s;

    &:before,
    &:after {
      position: absolute;
      content: "";
      background-image: url(../../img/common/kirakira.svg);
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      top: 50%;
      opacity: 0;
      transition: 0.9s cubic-bezier(0, 0.55, 0.45, 1);
      transition-delay: 0.9s;
    }
    &:before {
      transform: translateY(-50%) rotate(360deg);
    }
    &:after {
      transform: translateY(-50%) rotate(-360deg);
    }
  }
  &.active {
    .c__kirakira_border {
      width: calc(100% - 60px);
      padding: 0 30px;
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(-50%) rotate(0deg);
      }
      &:before {
        left: -30px;
      }
      &:after {
        right: -30px;
      }
      @include c.sp {
        padding: 0 20px;
        width: calc(100% - 40px);
        &:before,
        &:after {
          width: 10px;
          height: 10px;
        }
        &:before {
          left: -20px;
        }
        &:after {
          right: -20px;
        }
      }
    }
  }
}

.c__external_link {
  @include c.external_link_icon;
}
/*==============================
一覧系
==============================*/
.c__articles_grid {
  display: grid;
  gap: min(3vw, 40px);
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  transition: 0.3s;
  .thumbnail_anchor {
    display: block;
    position: relative;
    padding-top: v.$ratio_16_9;
    overflow: hidden;
    img {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(0);
      transition: 0.6s;
      will-change: filter opacity;
    }
    &:hover {
      img {
        transform: scale(105%);
      }
    }
    &.active {
      transform: scale(120%);
      transition: 0.6s;
      img {
        opacity: 0;
        filter: blur(10px);
        transition: 0.6s;
      }
    }
  }
  .date {
    font-size: 14px;
    font-family: v.$poppins;
    font-weight: v.$fw_medium;
    font-style: italic;
    margin-top: 24px;
    padding: 0 10px;
  }
  .title {
    font-size: 14px;
    min-height: 46px;
    font-weight: v.$fw_medium;
    margin: 8px 10px 0;
    @include c.line_clamp(2);
    @include c.xd_cropped_line_height(14, 26);
    @include c.mq_hover_device {
      transition: 0.15s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .title_border {
    margin-top: 20px;
    @include c.dashed_border($height: 1px, $dash: 1px, $spacing: 2px, $color: #bbb);
  }
  .category {
    font-size: 14px;
    font-weight: v.$fw_medium;
    padding: 24px 10px;
    border-bottom: solid 1px #bbb;
  }
  @include c.sp {
    grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
    gap: clamp(14px, 4vw, 26px);
    .date {
      font-size: 10px;
      margin: 10px 0;
      padding: 0;
    }
    .title {
      font-size: 12px;
      min-height: 38px;
      margin: 8px 0 0;
      @include c.xd_cropped_line_height(12, 20);
    }
    .title_border {
      margin-top: 14px;
    }
    .category {
      font-size: 10px;
      padding: 14px 0;
    }
  }
}
.c__discographies_grid {
  display: grid;
  gap: min(3vw, 50px) min(3vw, 46px);
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  transition: 0.3s;
  .thumbnail_anchor {
    display: block;
    position: relative;
    padding-top: v.$ratio_1_1;
    overflow: hidden;
    img {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(0);
      transition: 0.6s;
      will-change: filter opacity;
    }
    &:hover {
      img {
        transform: scale(105%);
      }
    }
    &.active {
      transform: scale(120%);
      transition: 0.6s;
      img {
        opacity: 0;
        filter: blur(10px);
        transition: 0.6s;
      }
    }
  }
  .title {
    display: block;
    margin-top: 12px;
    font-weight: v.$fw_medium;
    .title_line_clamp {
      font-size: 14px;
      min-height: 20px;
      @include c.line_clamp(1);
      @include c.xd_cropped_line_height(14, 26);
    }
    @include c.mq_hover_device {
      transition: 0.15s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @include c.tab {
  }
  @include c.sp {
    grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
    gap: clamp(14px, 4vw, 26px);
    .title {
      font-size: 12px;
    }
  }
}
.c__categories_list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 40px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: v.$noto;
  font-size: 14px;
  font-weight: v.$fw_medium;
  padding: 0 20px;
  @include c.sp {
    font-size: 12px;
    gap: 18px clamp(20px, 4vw, 26px);
    padding: 0;
  }
  li {
    display: inline-block;
    .category_buttons {
      transition: 1.15s;
      position: relative;
      padding-bottom: 12px;
      @include c.sp {
        padding-bottom: 6px;
      }
      &:before {
        transition: 0.5s;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: transparent;
        transition: all 0.3s;
        transform: scale(0, 1);
        transform-origin: center top;
      }
      &.active {
        &:before {
          transform: scale(1, 1);
          background-color: #fff;
        }
      }
      @include c.mq_hover_device {
        &:hover {
          &:before {
            transform: scale(1, 1);
            background-color: #fff;
          }
        }
      }
    }
  }
}
.c__pager {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(12px, 1vw, 14px);
  font-weight: v.$fw_extralight;
  font-family: v.$poppins;
  font-size: clamp(16px, 5vw, 20px);
  .disabled {
    display: none;
  }
  .elipsis {
    letter-spacing: 0.05em;
  }
  .underline {
    transition: 1.15s;
    padding-bottom: 10px;
    position: relative;
    &:before {
      transition: 0.5s;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: max(100%, 10px);
      height: 1px;
      background-color: transparent;
      transition: all 0.3s;
      transform: scale(0, 1) translateX(-50%);
      transform-origin: center top;
      @include c.sp {
        bottom: -6px;
      }
    }
    &.active {
      pointer-events: none;
      &:before {
        transform: scale(1, 1) translateX(-50%);
        background-color: #fff;
      }
    }
    @include c.mq_hover_device {
      &:hover {
        &:before {
          transform: scale(1, 1) translateX(-50%);
          background-color: #fff;
        }
      }
    }
  }
  .prev,
  .next {
    display: none;
  }
}
/*=======================================
  コンテンツエリア
=======================================*/
.c__article_body {
  font-family: v.$noto;
  font-size: 14px;
  font-weight: v.$fw_medium;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(40);
  line-height: f.xd_line_height(16, 32);
  h2 {
    font-size: 20px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h3 {
    font-size: 18px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h4 {
    font-size: 16px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h5 {
    font-size: 14px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  h6 {
    font-size: 14px;
    font-weight: v.$fw_bold;
    margin: 20px 0 10px;
    line-height: 1.5;
  }
  p {
    margin-bottom: 26px;
    strong {
      font-weight: v.$fw_bold;
    }
    em {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
    s {
      text-decoration: line-through;
    }
    a {
      text-decoration: underline;
      color: v.$letter_primary;
      transition: 0.15s;
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      margin: 20px auto;
      width: 100%;
    }
  }
  blockquote {
    position: relative;
    padding: 20px 40px;
    box-sizing: border-box;
    font-style: italic;
    color: #ccc;
    background: #01000a66;
    border-left: 2px solid #ccc;
    margin: 20px auto;
    p {
      padding: 0;
      margin: 7px 0;
      line-height: 1.7;
      a {
        text-decoration: underline;
        color: inherit;
      }
    }
  }
  ul {
    list-style-type: disc;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 2;
    }
  }
  ol {
    list-style-type: decimal;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 2;
    }
  }
  pre {
    padding: 0px;
    white-space: pre-wrap;
    code {
      padding: 20px;
      font-size: 15px;
      letter-spacing: 0.06em;
      line-height: 1.7em;
      span {
        max-width: 100%;
      }
    }
  }
  figcaption {
    font-size: 14px;
    line-height: 1.8;
  }
  .wp-block-embed-youtube .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    width: 100%;
  }
}

/*=======================================
  フォーム系
=======================================*/
@mixin form_base($height, $font_size: 16px) {
  width: 100%;
  height: $height;
  font-family: v.$noto; //明示
  font-size: $font_size;
  padding: 16px 20px;
  transition: 0.3s ease-in;
  color: v.$letter_primary;
  -webkit-appearance: none; //safariの角丸対策
  border-radius: 0px; //safariの角丸対策
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #bbb;
  outline: 0;
  &:focus {
    border: none;
    border-bottom: 1px solid #fff;
    background-color: rgba(#fff, 0.1);
  }
  &::placeholder {
    color: rgba(#fff, 0.5);
    font-family: v.$noto; //明示
    font-size: $font_size;
  }
}
.c__contact_label {
  font-size: 16px;
  font-weight: v.$fw_bold;
  color: v.$letter_primary;
  letter-spacing: 0.06em;
}
.c__contact_textbox {
  @include form_base(60px, 16px);
  @include c.sp {
    height: 48px;
    padding: 20px 18px;
  }
}
.c__contact_textarea {
  @include form_base(90px);
  max-height: 480px;
  resize: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}
.c__contact_radio,
.c__contact_checkbox {
  display: none;
  + label {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  + label:before {
    position: absolute;
    left: 0px;
    top: 4px;
    content: "";
    border: v.$border_primary;
    border-radius: 100vh;
    width: 16px;
    height: 16px;
    opacity: 1;
    transition: 0.3s;
  }
  &:checked + label:before {
    background-color: v.$notify_pink;
  }
  @include c.mq_hover_device {
    &:hover {
      + label:before {
        background-color: v.$white_opacity;
      }
      &:checked + label:before {
        background-color: v.$notify_pink;
      }
    }
  }
}
@mixin submit_button($height, $width) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  font-size: 18px;
  font-family: v.$noto; //明示
  font-weight: v.$fw_bold;
  text-decoration: none;
  color: v.$letter_primary;
  background: transparent;
  border: v.$border_primary;
  -webkit-appearance: none; //safariの角丸対策
  transition: 0.3s;
  cursor: pointer;
  @include c.mq_hover_device {
    &:hover {
      background-color: v.$white_opacity;
    }
  }
}
.c__submit_primary {
  @include submit_button(60px, 230px);
  border-radius: 100vh;
  &:disabled {
    @include submit_button(60px, 180px);
    color: white;
    background: #ccc;
    border: solid 0px #ffffff00;
    border-radius: 100vh;
    transition: 0.15s;
    pointer-events: none;
  }
  @include c.sp {
    width: 180px;
  }
}
.required::after {
  content: "必須";
  color: v.$notify_pink;
  font-size: 14px;
  padding-left: 10px;
}

/*=======================================
  アニメーション系
=======================================*/
.c__js_fade {
  transform: translateY(20px);
  opacity: 0;
  transition: all 4s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.active {
    transform: translateY(0) scale(100%);
    opacity: 1;
  }
  @include c.tran_delay(0.1);
}
.c__js_blur {
  .c__js_blur_child {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    filter: blur(10px);
  }
  &.active {
    .c__js_blur_child {
      opacity: 1;
      filter: blur(0px);
      @include c.tran_delay(0.2);
    }
  }
}
.js__transition {
  .js__transition_child {
    @include c.tran_delay(0.2);
  }
}
.js__animation {
  .js__animation_child {
    @include c.anim_delay(0.2);
  }
}
.js__load_required {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease;
  transform: translateY(3px);
  filter: blur(10px);
  transition: 0.03s;
  &.loaded {
    opacity: 1;
    pointer-events: auto;
    height: auto;
    transform: translateY(0);
    filter: blur(0px);
    transition: 1s;
    transition-delay: 0.05s;
  }
}
