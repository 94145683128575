@use "../foundation/common" as c;

.u__ib {
  display: inline-block;
}
.u__il {
  display: inline;
}
.u__bl {
  display: inline;
}
.u__none {
  display: none;
}
.u__pc_none {
  display: none;
  @include c.tab {
    display: block;
  }
  @include c.sp {
    display: block;
  }
}
.u__pc_only {
  display: block;
  @include c.tab {
    display: none;
  }
  @include c.sp {
    display: none;
  }
}
.u__tab_none {
  display: block;
  @include c.tab {
    display: none;
  }
  @include c.sp {
    display: block;
  }
}
.u__tab_only {
  display: none;
  @include c.tab {
    display: block;
  }
  @include c.sp {
    display: none;
  }
}
.u__sp_none {
  display: block;
  @include c.sp {
    display: none;
  }
}
.u__sp_only {
  display: none;
  @include c.sp {
    display: block;
  }
}
//== font-size 18px～32pxまで ==//
@for $i from 18 through 38 {
  .u__fs_#{$i} {
    font-size: #{$i}px;
  }
}
