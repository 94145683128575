@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/common" as c;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  font-family: v.$poppins;
  transition: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  .header_inner_pc {
    height: v.$header_height;
    padding: 0 v.$body_pd;
    display: flex;
    max-width: calc(v.$pc_min + v.$body_pd + v.$body_pd);
    width: 100%;
    margin: 0 auto;
    padding: 0 v.$body_pd;
    @include c.tab {
      display: none;
    }
    .gnav {
      display: flex;
      position: relative;
      font-size: clamp(12px, 1.8vw, 14px);
      font-weight: v.$fw_semibold;
      letter-spacing: f.xd_letter_spacing(40);
      width: 100%;
      gap: clamp(24px, 3vw, 40px);
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        pointer-events: none;
      }
      li:not(.logo) {
        a {
          pointer-events: auto;
          transition: 1.15s;
          position: relative;
          &:before {
            transition: 0.5s;
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: transparent;
            transition: all 0.3s;
            transform: scale(0, 1);
            transform-origin: center top;
          }
          &.active {
            &:before {
              transform: scale(1, 1);
              background-color: #fff;
            }
          }
          @include c.mq_hover_device {
            &:hover {
              &:before {
                transform: scale(1, 1);
                background-color: #fff;
              }
            }
          }
        }
      }
      .logo {
        position: absolute;
        width: clamp(120px, 12vw, 140px);
        height: 100%;
        top: 0;
        left: clamp(-210px, -12vw, -180px);
        a {
          pointer-events: auto;
          height: clamp(30px, 5vw, 32px);
          img {
            transition: 0.3s;
            position: absolute;
            top: 50%;
            transform: translate(0%, -50%);
            // aspect-ratio: auto 300 / 61;
          }
          .color {
            z-index: 10;
            visibility: hidden;
            opacity: 0;
          }
          @include c.mq_hover_device {
            &:hover {
              opacity: 1;
              .color {
                visibility: visible;
                opacity: 0.95;
              }
            }
          }
        }
        @include c.header_break {
          position: static;
          a {
            position: relative;
            width: 100%;
            display: flex;
            display: block;
          }
        }
      }
    }
    .header_sns_pc {
      display: flex;
      gap: 18px;
      pointer-events: auto;
      @include c.tab {
        display: none;
      }
    }
  }
  .sp_header_logo {
    position: fixed;
    top: 14px;
    left: 22px;
    width: 110px;
    display: none;
    z-index: 10;
    pointer-events: auto;
    @include c.tab {
      display: block;
    }
  }
  #sp_menu {
    height: calc(f.svh(100));
    width: 100%;
    transition: all 0.3s ease-in-out;
    background: #000231;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 15;
    &.active {
      @include c.tab {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
    .menu_inner {
      visibility: inherit;
      opacity: inherit;
      width: clamp(216px, 100%, 700px);
      padding: 0 clamp(30px, 10vw, 70px);
      margin: 0 auto;
    }
    .sp_menu_logo {
      margin: 70px auto 0 0;
      width: 120px;
      img .white {
        // aspect-ratio: auto 300 / 61;
      }
    }
    .gnav {
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      .link_wrapper {
        a {
          display: flex;
          font-family: v.$poppins;
          font-weight: v.$fw_semibold;
          font-size: 16px;
          gap: 16px;
          line-height: 1;
        }
        .name {
          display: block;
          flex-grow: 0;
          line-height: 1;
          padding-top: 4px;
          z-index: -1;
        }
        .decoration {
          display: flex;
          flex-grow: 1;
          position: relative;
          z-index: -1;
        }
        .line {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: calc(100% - 20px);
          height: 1px;
          background-color: #aaa;
        }
        .kira {
          display: block;
          position: absolute;
          background-image: url(../../img/common/kirakira.svg);
          background-repeat: no-repeat;
          width: 10px;
          height: 10px;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: 0.3s;
        }
      }
    }
    .header_sns_sp {
      display: flex;
      height: 20px;
      gap: clamp(20px, 6vw, 30px);
      margin-top: clamp(40px, 8vh, 70px);
    }
  }
  .header_sns .icon {
    display: block;
    width: 100%;
    height: 30px;
    position: relative;
    margin: auto 0;
    &.tw {
      width: 26px;
    }
    &.yt {
      width: 28px;
    }
    &.am {
      width: 24px;
    }
    &.sf {
      width: 26px;
    }
    &.bt {
      width: 26px;
    }
    &.ce {
      margin-left: -2px;
      width: 22px;
    }
    @include c.tab {
      width: 30px;
    }
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: 0.35s;
    }
    .white {
      visibility: visible;
      opacity: 1;
    }
    .color {
      z-index: 10;
      visibility: hidden;
      opacity: 0;
    }
    @include c.mq_hover_device {
      &:hover {
        .white {
          visibility: hidden;
          opacity: 0;
        }
        .color {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  #header_toggle {
    position: fixed;
    top: 1px;
    right: 1px;
    width: 60px;
    height: 60px;
    border-radius: 100vh;
    cursor: pointer;
    z-index: 20;
    display: none;
    background-color: transparent;
    pointer-events: auto;
    &:before {
      position: fixed;
      content: "";
      background-color: v.$white_opacity;
      border: v.$border_primary;
      top: 1px;
      right: 1px;
      width: 45px;
      height: 45px;
      border-radius: 100vh;
    }
    &:after {
      position: fixed;
      content: "";
      background-color: v.$white_opacity;
      border: v.$border_primary;
      width: 10px;
      height: 10px;
      border-radius: 100vh;
      right: 42px;
      top: 42px;
    }

    @include c.tab {
      display: block;
    }
    span {
      display: block;
      position: absolute;
      width: 18px;
      height: 1px;
      background-color: white;
      transition: 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
    }
  }
  $header_toggle_top: 17px;
  $header_toggle_top_offset: 6px;
  $header_toggle_right: 14px;
  #header_toggle span:nth-child(1) {
    top: $header_toggle_top;
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(2) {
    top: calc($header_toggle_top + $header_toggle_top_offset);
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(3) {
    top: calc($header_toggle_top + ($header_toggle_top_offset * 2));
    right: $header_toggle_right;
  }
  #header_toggle.active span:nth-child(1) {
    width: 20px;
    transform: translate(1px, 7px) rotate(220deg);
  }
  #header_toggle.active span:nth-child(2) {
    transform: translate(-50px, 0) scale(0);
  }
  #header_toggle.active span:nth-child(3) {
    width: 20px;
    transform: translate(1px, -5px) rotate(-220deg);
  }
}
#pagetop_button {
  position: fixed;
  bottom: -50px;
  right: 26px;
  opacity: 0;
  z-index: 7;
  transition: 0.7s;
  &.active {
    bottom: 26px;
    opacity: 1;
  }
  a {
    display: block;
    position: relative;
    background-color: v.$white_opacity;
    border: v.$border_primary;
    border-radius: 100vh;
    width: 60px;
    height: 60px;
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      display: inline-block;
      width: clamp(10px, 1.3vw, 16px);
      height: clamp(10px, 1.3vw, 16px);
      border-style: solid;
      border-color: #fff;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 0;
      border-left-width: 0;
      vertical-align: middle;
      transition: 0.2s;
      transform: translate(-50%, -25%) rotate(-45deg);
    }
    @include c.mq_hover_device {
      &:hover {
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg) scale(90%);
        }
      }
    }
  }
}

/*=======================================
  トップ
=======================================*/
.p__home {
  overflow: hidden;
  .home_first_view {
    width: 100%;
    height: f.svh(100);
    padding: clamp(80px, 10vmin, v.$header_height) 20px min(6vmin, 80px);
    .fv_slider_wrapper {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .news_index_link {
      width: 100%;
      position: absolute;
      bottom: clamp(-100px, -14vmin, -50px);
      left: 0;
      display: grid;
      place-items: center;
      margin: 0 auto;
      .c__plus_button {
        background-color: #162d79;
        width: clamp(30px, 8vh, 60px);
        height: clamp(30px, 8vh, 60px);
      }
    }
    .fv_head {
      position: absolute;
      top: -10%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      font-family: v.$poppins;
      font-size: 3vmin;
      font-weight: v.$fw_bold;
    }
    .fv_frame {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: min(100%, 130vh);
      height: 100%;
    }
    .fv_frame_pc {
      display: block;
    }
    .fv_frame_tab {
      display: none;
    }
    .fv_frame_sp {
      display: none;
    }
    .thumbnail_anchor {
      display: block;
      position: relative;
      padding-top: v.$ratio_16_9;
      border-radius: clamp(10px, 0.5vw, 16px);
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.6s;
      }
      &:hover {
        img {
          transform: scale(105%);
        }
      }
      &.disabled {
        &:hover {
          img {
            transform: none;
          }
        }
      }
    }
    .fv_swiper {
      position: absolute;
      overflow: visible;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -47%);
      width: min(75%, f.svh(100));
      .swiper-slide {
        img {
          // aspect-ratio: auto 158 / 211;
          // height: auto;
        }
        &:not(.swiper-slide-active) {
          .thumbnail_anchor {
            transform: scale(99.9%);
          }
        }
      }
      .swiper-pagination {
        bottom: -26px;
        .swiper-pagination-bullet {
          border: v.$border_primary;
          background: transparent;
          opacity: 1;
          transition: 0.3s;
          &.swiper-pagination-bullet-active {
            background: #fff;
          }
        }
      }
    }
    animation: fv_breath 20s ease infinite;
    @keyframes fv_breath {
      0% {
        transform: translate3d(0, max(-2vh, 22px), 0);
      }
      50% {
        transform: translate3d(0, min(2vh, -22px), 0);
      }
      100% {
        transform: translate3d(0, max(-2vh, 22px), 0);
      }
    }
    @include c.tab {
      height: 100vh;
      padding: 0;
      width: 100%;
      animation: fv_breath_sp 18s ease infinite;
      @keyframes fv_breath_sp {
        0% {
          transform: translate3d(0, 0, 0);
        }
        50% {
          transform: translate3d(0, clamp(20px, 10vh, 40px), 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
      .fv_frame_pc {
        display: none;
      }
      .fv_frame_tab {
        display: block;
        width: 100%;
        transform: translate(-50%, -62%);
      }
      .fv_frame_sp {
        display: none;
      }
      .fv_head {
        top: -16%;
        font-size: 22px;
      }
      .fv_swiper {
        top: 42%;
        transform: translate(-50%, -50%);
        width: 70vw;
      }
      .news_index_link {
        bottom: -35%;
        .c__plus_button {
          width: 60px;
          height: 60px;
        }
      }
    }
    @include c.sp {
      padding: 0;
      animation: fv_breath_sp 16s ease infinite;
      .fv_frame_pc {
        display: none;
      }
      .fv_frame_tab {
        display: none;
      }
      .fv_frame_sp {
        display: block;
        width: 100%;
        transform: translate(-50%, -61%);
      }
      .fv_head {
        top: -9vw;
        font-size: clamp(16px, 4vw, 28px);
      }
      .fv_swiper {
        width: 100vw;
        max-width: 100%;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .thumbnail_anchor {
        border-radius: 0px;
      }
      .news_index_link {
        bottom: clamp(-130px, -21vw, -74px);
        .c__plus_button {
          width: clamp(40px, 12vw, 60px);
          height: clamp(40px, 12vw, 60px);
          &:before {
            width: clamp(14px, 5vw, 28px);
          }
          &:after {
            height: clamp(14px, 5vw, 28px);
          }
        }
      }
    }
    @include c.fv_height_break {
      padding: 0;
      animation: fv_breath 20s ease infinite;
      .fv_head {
        top: -4vmin;
        font-size: 2vmin;
        font-weight: v.$fw_bold;
      }
      .fv_frame_pc {
        display: block;
        top: 50%;
      }
      .fv_frame_tab {
        display: none;
      }
      .fv_frame_sp {
        display: none;
      }
      .fv_swiper {
        top: 52%;
        width: 80vmin;
        transform: translate(-50%, -50%);
        .swiper-pagination {
          display: none;
        }
      }
      .news_index_link {
        bottom: -38%;
        .c__plus_button {
          transform: scale(80%);
        }
      }
    }
  }
  #scrolldown {
    display: none;
    z-index: -1;
    transition: 3s;
    @include c.tab {
      display: block;
      position: absolute;
      top: f.svh(80);
      left: 50%;
      transform: translate(-50%, 0%);
    }
    @media (max-height: 600px) {
      display: none;
    }
    @media (orientation: landscape) {
      display: none;
    }
    span {
      margin: 0 auto clamp(8px, 2.5vw, 14px);
    }
    .circle {
      display: block;
      border: solid 1px #ddd;
      border-radius: 100vh;
    }
    $scrolldown_circle_size: clamp(16px, 2vw, 20px);
    $scrolldown_delay: 0.25s;
    $scrolldown_duration: 6s;
    $scrolldown_ease: cubic-bezier(0.05, 0.55, 0.48, 0.92);
    .first {
      width: calc($scrolldown_circle_size);
      height: calc($scrolldown_circle_size);
      animation: scrolldown $scrolldown_duration $scrolldown_ease infinite;
      animation-delay: calc($scrolldown_delay * 1);
    }
    .second {
      width: calc($scrolldown_circle_size - 4px);
      height: calc($scrolldown_circle_size - 4px);
      animation: scrolldown $scrolldown_duration $scrolldown_ease infinite;
      animation-delay: calc($scrolldown_delay * 2);
    }
    .third {
      width: calc($scrolldown_circle_size - 8px);
      height: calc($scrolldown_circle_size - 8px);
      animation: scrolldown $scrolldown_duration $scrolldown_ease infinite;
      animation-delay: calc($scrolldown_delay * 3);
    }
    .fourth {
      width: calc($scrolldown_circle_size - 12px);
      height: calc($scrolldown_circle_size - 12px);
      animation: scrolldown $scrolldown_duration $scrolldown_ease infinite;
      animation-delay: calc($scrolldown_delay * 4);
    }
    .arrow {
      display: block;
      width: $scrolldown_circle_size;
      height: $scrolldown_circle_size;
      position: relative;
      animation: scrolldown $scrolldown_duration $scrolldown_ease infinite;
      animation-delay: calc($scrolldown_delay * 5);
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: calc($scrolldown_circle_size / 2);
        height: 1px;
        background-color: #ddd;
      }
      &:before {
        top: 4px;
        left: 50%;
        transform: translate(calc(-50% + -3px), 0) rotate(45deg);
      }
      &:after {
        top: 4px;
        left: 50%;
        transform: translate(calc(-50% + 3px), 0) rotate(-45deg);
      }
    }
    @keyframes scrolldown {
      0% {
        opacity: 0;
        transform: translate(0, 0);
      }
      3% {
        opacity: 1;
      }
      60% {
        opacity: 1;
      }
      70% {
        transform: translate(0, clamp(20px, 5vh, 30px));
        opacity: 0;
      }
      100% {
        transform: translate(0, 30px);
        opacity: 0;
      }
    }
  }
  .home_profile {
    margin: 100px auto 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 50px;
    max-width: calc(v.$home_content_width + v.$body_pd + v.$body_pd);
    padding: 0 v.$body_pd;
    .tab_profile_wrapper {
      display: contents;
    }
    .profile_wrapper {
      width: 120%;
    }
    .name_en {
      width: 180px;
      margin: 0 auto 28px;
      img {
        // aspect-ratio: auto 178.488 / 19.128;
      }
    }
    .name_ja {
      width: 100%;
      margin: 0 auto 50px;
      .kanji {
        padding: clamp(20px, 5vw, 36px) clamp(20px, 8vw, 56px);
        display: block;
        // aspect-ratio: auto 340.62 / 64.47;
      }
    }
    .c__vertical_head_pc_none {
      display: none;
    }
    .description_wrapper {
      display: flex;
      gap: 40px;
      .description {
        font-size: 14px;
        text-align: justify;
        @include c.xd_cropped_line_height(14, 26);
      }
      .profile_text {
        margin-bottom: 20px;
      }
      .links {
        display: flex;
        gap: 12px;
        .service {
          min-width: 60px;
        }
        a {
          display: inline-block;
          text-align: justify;
          transition: 0.3s;
          position: relative;
          padding-bottom: 6px;
          margin-bottom: 6px;
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #aaa;
          }
        }
      }
      .endorfin_head {
        width: 110px;
        margin: 40px auto 20px 0;
        img {
          // aspect-ratio: auto 109.152 / 19.128;
        }
      }
    }
    .profile_swiper_wrapper {
      width: 120%;
    }
    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 95%;
      background-color: #030646;
      border: v.$border_primary;
      border-radius: 100vh 100vh 60px 60px;
    }
    .profile_swiper {
      width: 100%;
      padding-bottom: 56px;
      .swiper-pagination {
        bottom: 16px;
        .swiper-pagination-bullet {
          border: v.$border_primary;
          background: transparent;
          opacity: 1;
          transition: 0.3s;
          &.swiper-pagination-bullet-active {
            background: #fff;
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        top: auto;
        bottom: 18px;
        left: auto;
        right: auto;
        color: white;
        width: 14px;
        height: 14px;
        z-index: 15;
        &:after {
          opacity: 0.8;
          font-size: 14px;
          width: 100%;
          height: 100%;
        }
      }
      .swiper-button-prev {
        right: clamp(230px, 28vw, 286px);
      }
      .swiper-button-next {
        left: clamp(230px, 28.4vw, 292px);
      }
    }
    @include c.tab {
      margin: 0 auto;
      gap: min(3vw, 40px);
      .description_wrapper .profile_text {
        margin-bottom: 14px;
        font-size: clamp(12px, 1.6vw, 14px);
      }
      .links {
        font-size: clamp(12px, 1.6vw, 14px);
        a {
          padding-bottom: 4px;
        }
      }
      .c__vertical_head {
        font-size: clamp(26px, 10vw, 58px);
      }
      .c__vertical_head_pc_only {
        display: none;
      }
      .c__vertical_head_pc_none {
        display: grid;
        width: clamp(40px, 6vw, 60px);
        font-size: clamp(24px, 4vw, 58px);
      }
      .profile_wrapper {
        width: 100%;
      }
      .description_wrapper {
        .endorfin_head {
          width: 100px;
          margin: 30px auto 14px 0;
        }
      }
      .profile_swiper {
        width: 115%;
        .swiper-button-prev,
        .swiper-button-next {
          bottom: 15%;
          width: clamp(20px, 4vw, 30px);
          height: clamp(30px, 6vw, 48px);
          &:after {
            opacity: 1;
            font-family: v.$poppins;
            content: "";
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        .swiper-button-prev {
          right: auto;
          left: clamp(20px, 3vw, 30px);
          &:after {
            background-image: url(../../img/common/arrow_l.svg);
          }
        }
        .swiper-button-next {
          left: auto;
          right: clamp(20px, 3vw, 30px);
          &:after {
            background-position-x: right;
            background-image: url(../../img/common/arrow_r.svg);
          }
        }
      }
      .tab_profile_wrapper {
        display: flex;
        gap: min(3vw, 40px);
      }
      .name_en {
        display: flex;
        width: clamp(80px, 18vw, 160px);
        margin: 0 auto 20px;
      }
      .name_ja {
        width: 100%;
        margin: 0 auto clamp(30px, 4vw, 40px);
        .kanji {
          padding: clamp(20px, 3vw, 30px) clamp(20px, 6vw, 50px);
          display: block;
        }
      }
    }
    @include c.sp {
      flex-direction: column;
      max-width: 550px;
      margin: 0 auto;
      gap: min(5vw, 50px);
      .tab_profile_wrapper {
        gap: min(8vw, 40px);
      }
      .description_wrapper {
        .endorfin_head {
          width: 92px;
        }
      }
      .profile_swiper {
        width: 100%;
        .swiper-button-prev,
        .swiper-button-next {
          width: clamp(20px, 8vw, 40px);
          height: clamp(34px, 10vw, 68px);
        }
        .swiper-button-prev {
          left: clamp(20px, 7vw, 60px);
        }
        .swiper-button-next {
          right: clamp(20px, 7vw, 60px);
        }
      }
      .description_wrapper .profile_text {
        margin-bottom: 20px;
        font-size: clamp(12px, 2vw, 14px);
      }
      .name_en {
        width: clamp(100px, 30vw, 160px);
        margin: 0 auto clamp(20px, 4vw, 30px);
      }
      .name_ja {
        .kanji {
          padding: clamp(20px, 5vw, 36px) clamp(20px, 8vw, 56px);
        }
      }
    }
  }

  .home_discography {
    margin: 260px auto 0;
    width: 100%;
    max-width: calc(v.$home_content_width + v.$body_pd + v.$body_pd);
    padding: 0 v.$body_pd;
    .discography_wrapper {
      display: flex;
      gap: 70px;
    }
    .discography_list {
      width: 100%;
      flex: 1;
    }
    .c__discographies_grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .discography_index_link {
      display: grid;
      place-items: center;
      margin: 60px auto 0;
    }
    .article_notfound {
      margin: 0 auto 80px;
    }
    @include c.tab {
      margin-top: 180px;
      width: 100%;
      .c__discographies_grid {
        grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
      }
      .discography_wrapper {
        gap: 40px;
      }
    }
    @include c.sp {
      margin-top: 80px;
      .discography_wrapper {
        gap: clamp(16px, 5vw, 30px);
      }
      .discography_index_link {
        margin-top: 30px;
      }
    }
    @media (max-width: 490px) {
      .c__discographies_grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      }
    }
  }
  .home_contact {
    margin: 270px auto 300px;
    width: 100%;
    max-width: calc(760px + v.$body_pd + v.$body_pd);
    padding: 0 v.$body_pd;
    text-align: center;
    position: relative;
    @include c.sp {
      margin: 110px auto 140px;
    }
    .kurage_img {
      width: clamp(160px, 15vw, 210px);
      position: absolute;
      bottom: clamp(200px, 80vw, 490px);
      left: clamp(340px, 63vw, 900px);
      animation: kurage_float 16s ease infinite;
      // aspect-ratio: auto 398 / 474;
      @keyframes kurage_float {
        0% {
          transform: translate(0, 0);
        }
        50% {
          transform: translate(0, 2vw);
        }
        100% {
          transform: translate(0, 0);
        }
      }
      @include c.tab {
        width: clamp(110px, 20vw, 160px);
        left: clamp(200px, 74vw, 630px);
      }
      @include c.sp {
        width: clamp(70px, 20vw, 160px);
        bottom: auto;
        top: calc(-100px - 10vw);
      }
      &:hover {
        animation: kurage_yureru 1s ease;
        @keyframes kurage_yureru {
          0% {
            transform: translate(2px, 0.5vw) rotate(-5deg);
            animation-timing-function: ease;
          }
          5% {
            transform: translate(-2px, 1.2vw) rotate(-3deg);
            animation-timing-function: ease;
          }
          10% {
            transform: translate(2px, 1.2vw) rotate(5deg);
            animation-timing-function: ease;
          }
          20% {
            transform: translate(-2px, 1vw) rotate(3deg);
            animation-timing-function: ease;
          }
          30% {
            transform: translate(2px, 0.5vw);
            animation-timing-function: ease;
          }
          40% {
            transform: translate(-2px, 0px);
            animation-timing-function: ease;
          }
        }
      }
    }
    .c__head_primary {
      margin-bottom: 30px;
      @include c.sp {
        margin-bottom: 24px;
      }
    }
    .c__common_text {
      text-align: center;
      &.medium {
        font-weight: v.$fw_medium;
      }
      &.light {
        font-weight: v.$fw_light;
      }
      &.u__mng {
        margin: 58px auto 10px;
      }
      &.u__qa {
        margin-bottom: 44px;
      }
      &.u__fanletter {
        margin-top: 26px;
      }
      @include c.sp {
        &.u__mng {
          margin: 40px auto 10px;
        }
        &.u__qa {
          margin-bottom: 30px;
        }
      }
    }
    .contact_link {
      margin-bottom: 66px;
      @include c.sp {
        margin-bottom: 40px;
      }
    }
    // アニメーション系の設定
    .c__head_primary {
      overflow: hidden;
      span {
        display: block;
        transform: translate(0, 30px);
        transition: transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
      }
    }
    &.active {
      .c__head_primary {
        span {
          transform: translate(0, 0);
        }
      }
    }
    .c__common_text {
      transform: translateY(20px);
      opacity: 0;
      will-change: opacity;
    }
    &.active {
      .c__common_text {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .contact_link {
      transform: translateY(20px);
      opacity: 0;
      will-change: opacity;
    }
    &.active {
      .contact_link {
        transform: translateY(0);
        opacity: 1;
      }
    }
    // トランジションの設定
    .c__head_primary {
      span {
        transition: transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
      }
    }
    .c__kirakira_border {
      transition-delay: 0.3s;
      &:before,
      &:after {
        transition-delay: 0.9s;
      }
    }
    .c__common_text {
      transition: 1.2s ease;
      transition-delay: 1.1s;
    }
    .contact_link {
      transition: 1.2s ease;
      transition-delay: 1.4s;
    }
    .u__fanletter {
      transition-delay: 1.7s;
    }
  }
}

/*=======================================
  記事一覧
=======================================*/
.p__articles_index {
  padding-top: calc(v.$header_height + 90px);
  min-height: v.$max_svh;
  @include c.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .c__head_primary {
    margin-bottom: 100px;
    @include c.sp {
      margin-bottom: 66px;
    }
  }
  .c__categories_list {
    margin-bottom: 82px;
    @include c.sp {
      margin-bottom: 40px;
    }
  }
  .c__articles_grid {
    position: relative;
    margin-bottom: 98px;
  }
  .c__pager {
    margin-bottom: 94px;
    @include c.sp {
      margin-bottom: 64px;
    }
  }
  .c__button_underline {
    margin: 0 auto 40px;
    @include c.tab {
      margin-bottom: 100px;
    }
  }
  .article_notfound {
    margin: 0 auto 80px;
    text-align: center;
  }
}

/*=======================================
  記事詳細
=======================================*/
.p__articles_show {
  padding-top: calc(80px + v.$header_height);
  min-height: v.$max_svh;
  @include c.tab {
    padding-top: calc(40px + v.$header_height_sp);
  }
  .article_head {
    margin: 0 0 40px 0;
  }
  .article_title {
    font-family: v.$poppins;
    font-size: 28px;
    font-weight: v.$fw_semibold;
    margin-bottom: 14px;
    @include c.sp {
      font-size: 16px;
    }
  }
  .article_head_info {
    display: flex;
    align-items: baseline;
    gap: 40px;
    font-size: 14px;
    font-weight: v.$fw_medium;
    @include c.sp {
      font-size: 12px;
    }
  }
  .article_published_at {
    font-family: v.$poppins;
    font-style: italic;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background-color: #fff;
    }
  }
  .article_thumbnail {
    margin-bottom: 100px;
    position: relative;
    padding-top: v.$ratio_16_9;
    @include c.sp {
      margin-bottom: 40px;
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .c__article_body {
    max-width: 760px;
    margin: 0 auto 130px;
    @include c.sp {
      margin: 0 auto 100px;
    }
  }

  // SNSシェアボタンと前後記事ボタンの幅を連動させるための変数
  $article_prev_next_padding: clamp(20px, 3vw, 30px);
  $article_prev_next_arrow_size: clamp(40px, 6vw, 60px);

  .c__sns_share {
    margin: 0 auto $article_prev_next_arrow_size;
    width: calc(($article_prev_next_padding * 2) + ($article_prev_next_arrow_size * 2));
    height: $article_prev_next_arrow_size;
  }
  .article_prev_next {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 84px;
    .link_wrapper_prev {
      padding-right: $article_prev_next_padding;
      justify-content: flex-end;
    }
    .link_wrapper_next {
      padding-left: $article_prev_next_padding;
      justify-content: flex-start;
    }
    .link_wrapper {
      position: relative;
      display: flex;
      flex-grow: 1;
      width: 100%;
      .pager_link {
        display: flex;
        transition: 0.2s;
        align-items: center;
        &:hover {
          .arrow {
            background-color: #fff2;
          }
        }
        &.disabled {
          pointer-events: none;
        }
      }
    }
    .arrow {
      width: $article_prev_next_arrow_size;
      height: $article_prev_next_arrow_size;
      min-width: $article_prev_next_arrow_size;
      min-height: $article_prev_next_arrow_size;
      border: v.$border_primary;
      border-radius: 100vh;
      transition: 0.2s;
      position: relative;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        display: inline-block;
        width: clamp(10px, 1.3vw, 16px);
        height: clamp(10px, 1.3vw, 16px);
        border-style: solid;
        border-color: #fff;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 0;
        border-left-width: 0;
        vertical-align: middle;
      }
      &.left {
        &:before {
          transform: translate(calc(-50% + clamp(3px, 0.4vw, 5px)), -50%) rotate(-135deg);
        }
      }
      &.right {
        &:before {
          transform: translate(calc(-50% - clamp(3px, 0.4vw, 5px)), -50%) rotate(45deg);
        }
      }
      &.disabled {
        border: solid 1px #555;
        &:before {
          border-color: #555;
        }
      }
    }
    @include c.sp {
      margin-bottom: 60px;
    }
  }

  .c__button_underline {
    margin: 0 auto 200px;
    @include c.tab {
      margin-bottom: 100px;
    }
  }
}

/*=======================================
  ディスコグラフィ一覧
=======================================*/
.p__discographies_index {
  padding-top: calc(v.$header_height + 90px);
  min-height: v.$max_svh;
  @include c.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .c__head_primary {
    margin-bottom: 100px;
    @include c.sp {
      margin-bottom: 66px;
    }
  }
  .c__categories_list {
    margin-bottom: 82px;
    @include c.sp {
      margin-bottom: 40px;
    }
  }
  .c__discographies_grid {
    margin-bottom: 98px;
    position: relative;
  }
  .c__pager {
    margin-bottom: 94px;
    @include c.sp {
      margin-bottom: 64px;
    }
  }
  .c__button_underline {
    margin: 0 auto 40px;
  }
  .article_notfound {
    margin: 0 auto 80px;
    text-align: center;
  }
}

/*=======================================
  ディスコグラフィ詳細
=======================================*/

.p_discography_show {
  padding-top: calc(v.$header_height + 90px);
  min-height: v.$max_svh;
  @include c.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .c__head_primary {
    margin-bottom: 70px;
    @include c.sp {
      margin-bottom: 58px;
    }
  }
  .modal_article {
    max-width: 600px;
    padding: 0 v.$body_pd;
    z-index: 1000;
    position: relative;
    .modal_thumbnail {
      position: relative;
      padding-top: v.$ratio_1_1;
      margin-bottom: 68px;
      @include c.tab {
        margin-bottom: clamp(30px, 6vw, 40px);
      }
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .c__x_button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
    .modal_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 42px;
      @include c.sp {
        flex-direction: column-reverse;
        gap: 40px;
        align-items: stretch;
      }
    }
    .special_site {
      padding-right: 4em;
      position: relative;
      font-size: clamp(12px, 3vw, 16px);
      text-align: right;
      min-width: 180px;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: clamp(30px, 2.7em, 40px);
        height: clamp(30px, 2.7em, 40px);
        background-color: transparent;
        border: v.$border_primary;
        border-radius: 100vh;
        transition: 0.3s;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        background-image: url(../../img/common/link_external.svg);
        background-repeat: no-repeat;
        width: clamp(12px, 1.2em, 18px);
        height: clamp(12px, 1.2em, 18px);
        top: 50%;
        right: 2px;
        transform: translate(-50%, -50%);
        transition: 0.3s;
      }
      @include c.mq_hover_device {
        &:hover {
          &:before {
            background-color: v.$white_opacity;
          }
        }
      }
    }
    .modal_head {
      font-size: 18px;
      font-weight: v.$fw_medium;
    }
    .c__article_body {
      margin-bottom: 100px;
    }
    .c__button_underline {
      margin: 0 auto 60px;
      @include c.tab {
        margin-bottom: 40px;
      }
    }
  }
}

/*=======================================
  お問い合わせ
=======================================*/
.p__contact {
  @include c.content_width(600px);
  padding-top: calc(v.$header_height + 90px);
  min-height: v.$max_svh;
  @include c.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .c__head_primary {
    margin-bottom: 76px;
    @include c.sp {
      margin-bottom: 66px;
    }
  }
  .c__head_description {
    margin-bottom: 56px;
    @include c.sp {
      margin-bottom: 44px;
    }
  }
  .fanfic_policy {
    margin-bottom: 100px;
  }
  .input_wrapper {
    margin-bottom: 64px;
  }
  #radio_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 40px 0 0 20px;
    @include c.sp {
      margin: 40px 0 0 0;
    }
  }
  .c__contact_label {
    display: block;
    margin-bottom: 10px;
  }
  .textarea_wrapper {
    margin-bottom: 24px;
    overflow: hidden;
  }
  .agreement {
    font-size: 15px;
    margin: 120px 0 60px;
    text-align: center;
    @include c.sp {
      margin: 100px 0 60px;
    }
    label {
      margin: 0 0 40px 0;
      &:before {
        top: 3px;
      }
    }
    .u__agreement_notice {
      margin: 0 0 20px 0;
    }
    a {
      text-decoration: underline;
      line-height: 1;
      &:hover {
        transition: 0.3s;
        opacity: 0.8;
      }
    }
    .c__contact_label {
      margin: 50px 0 20px;
    }
  }
  #js_err_existence {
    font-size: 16px;
    font-weight: v.$fw_medium;
    .u__error_list {
      display: block;
      font-size: 14px;
      &:nth-last-child(1) {
        margin-bottom: 80px;
      }
    }
  }
  .c__error_message {
    margin-top: 20px;
  }
  .c__submit_primary {
    margin: 0 auto 200px;
  }
}
.p__contact_submitted {
  min-height: v.$max_svh;
  padding-top: calc(v.$header_height);
  @include c.tab {
    padding-top: calc(v.$header_height_sp);
  }
  .c__head_primary {
    margin: 76px 0 90px;
    @include c.sp {
      margin: 66px 0 40px;
    }
  }
  .c__head_description {
    margin-bottom: 56px;
    @include c.sp {
      text-align: left;
      margin-bottom: 44px;
    }
  }
  .c__submit_primary {
    margin: 0 auto 20px;
  }
}
.p__notfound {
  min-height: v.$max_svh;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include c.tab {
    padding-top: calc(v.$header_height_sp);
  }
  .c__head_primary {
    margin: 76px 0 40px;
    @include c.sp {
      margin: 66px 0 40px;
    }
  }
  .c__head_description {
    margin-bottom: 80px;
    @include c.sp {
      text-align: center;
      margin-bottom: 60px;
    }
  }
  .c__submit_primary {
    margin: 0 auto 40px;
  }
}

/*=======================================
  フッター
=======================================*/
#footer {
  width: 100%;
  font-family: v.$noto;
  color: v.$letter_primary;
  position: relative;
  z-index: 5;
  height: v.$footer_height;
  padding-top: 40px;
  .copyright {
    font-size: 12px;
    text-align: center;
    font-weight: v.$fw_medium;
    opacity: 0;
    @include c.mq_hover_device {
      a {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #aaa;
          transition: 0.3s;
          transform: scale(0, 1);
          transform-origin: center top;
        }
        &:hover {
          &:before {
            transform: scale(1, 1);
          }
        }
      }
    }
  }
  &.active {
    .copyright {
      transition: 0.5s;
      transition-delay: 0.4s;
      opacity: 1;
    }
  }
}
#footer_background {
  width: 100%;
  position: relative;
  z-index: -1;
  .kaitei_l,
  .kaitei_r {
    position: absolute;
    z-index: -3;
    width: clamp(300px, 32vw, 600px);
    bottom: 0;
    opacity: 0;
    transition: 0s;
  }
  .kaitei_l {
    left: 0;
  }
  .kaitei_r {
    right: 0;
  }
  .deep_sea_gradation {
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: f.svh(100);
    background: linear-gradient(180deg, rgba(#000, 0) 0%, rgba(#000, 0.75) 100%);
  }
  &.active {
    .kaitei_l,
    .kaitei_r {
      transition: 2s;
      transition-delay: 0.4s;
      opacity: 0.6;
      will-change: opacity;
    }
  }
}

/*=======================================
  マウスストーカー
=======================================*/
#mouse_stalker {
  pointer-events: none;
  @include c.mq_hover_device {
    position: fixed;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background: v.$white_opacity;
    border: 1px solid #aaa;
    border-radius: 100vh;
    transform: translate(0, 0);
    transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 99999;
    &.stalker_cursor_pointer {
      top: -30px;
      left: -30px;
      width: 60px;
      height: 60px;
      &:before {
        content: "";
        position: fixed;
        display: block;
        background: transparent;
        border: 1px solid #fff;
        top: -2px;
        left: -2px;
        width: 59px;
        height: 59px;
        border-radius: 100vh;
        animation: link_appeal 2s ease-out infinite;
        @keyframes link_appeal {
          0% {
            width: 59px;
            height: 59px;
            opacity: 0.8;
          }
          85% {
            opacity: 0;
          }
          90% {
            top: -21px;
            left: -21px;
            width: 100px;
            height: 100px;
            opacity: 0;
          }
          100% {
            top: -21px;
            left: -21px;
            width: 100px;
            height: 100px;
            opacity: 0;
          }
        }
      }
    }
  }
  @include c.mq_touch_device {
    display: none;
  }
}

/*=======================================
  背景系
=======================================*/
#background {
  position: relative;
  z-index: -50;
  min-height: f.svh(100);
  background-color: #020434;
  &:before {
    transition: 0.7s;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: v.$navy_grad;
    z-index: -50;
  }
  &.active {
    &:before {
      opacity: 1;
      transition: 1s;
    }
  }
}
#parallax {
  width: 100%;
  height: 100%;
  transform: translateY(0px);
  transition: 5s cubic-bezier(0.18, 1.7, 0.62, 0.94);
}
#big_bubbles_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: 0.15s;
  pointer-events: none;
  filter: blur(10px);
  &.active {
    visibility: visible;
    opacity: 1;
    filter: blur(0px);
    transition: 3s;
    transition-delay: 0.4s;
  }
}
#smallBubble {
  position: fixed;
  height: 100%;
  width: 100vw;
  max-width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -10;
  .bubble_dot {
    opacity: 0;
    position: absolute;
    background-color: rgba(#eee, 0.7);
    animation: small_bubble;
    animation-timing-function: ease-in;
    // durationはjsで設定
    border-radius: 100vh;
    @include c.sp {
      animation: small_bubble_sp;
      background-color: rgba(#fff, 0.4);
    }
  }
  @keyframes small_bubble {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    5% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    43% {
      opacity: 0.7;
    }
    46% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      transform: translate(0, -60vh);
    }
  }
  @keyframes small_bubble_sp {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    5% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    43% {
      opacity: 0.7;
    }
    46% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      transform: translate(0, -30vh);
    }
  }
}
