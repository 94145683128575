@use "../foundation/functions" as f;

// 色系
$pink: #FD789A;
$navy: #162D79;
$dark_navy: #00012B;
$navy_grad: linear-gradient(180deg, #162D79 0%, #020543 30%, #020434 80%, #00012B 100%);
$white_opacity: rgba(255, 255, 255, 0.2);
$selection_bg: #ffdbee;
$selection_text: #162D79;

$letter_primary: #FFF;
$notify_pink: #ff83c6;

$border_primary: 1px solid #fff;

// フォント系
$noto: "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$poppins: 'Poppins', "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //extralight: 200 semibold: 600
$fw_extralight: 200;
$fw_light: 300;
$fw_normal: 400;
$fw_medium: 500;
$fw_semibold: 600;
$fw_bold: 700;

//ヘッダーフッターの高さ
$header_height: 100px;
$header_height_sp: 60px;
$footer_height: clamp(120px, 20vw,160px);

// コンテンツ幅管理
$home_content_width: 948px;

// 画面いっぱいの高さ
// $max_svh: calc(f.static_svh(100) - $footer_height);
$max_svh: calc(f.svh(100) - $footer_height);

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

// メディアクエリー用
$header_break: 1560px;
$pc_min: 1100px;
$tab_max: 900px;
$sp_max: 712px;
$sp_max_s: 416px;
$layout_min: 320px;
$body_pd: 40px;
$contact_pd: 48px;

// vw変換用(XDキャンバス幅pxに合わせる)
$vp_basis_pc: 1920;
$vp_basis_sp: 375;

/*=======================================
  z-index管理
=========================================

---- 99999 ----
#mouse_stalker

---- 1000 -----
#portal .modal_article

---- 900 -----
#portal

----- 20 -----
#header_toggle
#portal #modal_background

----- 15 -----
#sp_menu

----- 10 -----
#header
#header. header_inner_pc .gnav .logo .color
#header. header_sns .icon .color
.sp_header_logo

----- 7 -----
#pagetop_button
#big_bubbles_wrapper

------ 5 ------
.p__home .home_first_view .fv_head
#footer .copyright

------ 2 ------


------ 1 ------
.p__home .fv_slider_wrapper

------ -1 ------
#scrolldown
#footer_background

------ -10 -----
#smallBubble

------ -50 -----
#background
#background:before

------ -100 ----
#root

------ -1000 ----
body

*/