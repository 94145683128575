@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/common" as c;

//コンテンツ幅内でめいっぱい広げる
.l__content_width {
  max-width: calc(v.$pc_min + v.$body_pd + v.$body_pd);
  width: 100%;
  margin: 0 auto;
  padding: 0 v.$body_pd;
}

/*=======================================
  flex系
=======================================*/
//常にflex
.l__flexbox {
  display: flex;
}
// 均等配置
.l__flex_space_between {
  justify-content: space-between;
}
//flex逆方向
.l__flex_reverse {
  flex-flow: row-reverse;
}
//上下中央寄せ
.l__flex_ai_center {
  align-items: center;
}
//左右中央寄せ
.l__flex_jc_center {
  justify-content: center;
}
//高さを揃える
.l__flex_stretch {
  align-items: stretch;
}
//PCの場合のみflex
.l__flexbox_pc {
  display: flex;
  @include c.tab() {
    display: block;
  }
}
//PCとtabの場合のみflex
.l__flexbox_pc_tab {
  display: flex;
  @include c.sp() {
    display: block;
  }
}
//tabとspの場合のみflex
.l__flexbox_tab_sp {
  display: block;
  @include c.tab() {
    display: flex;
  }
}
.l__iframe_wrapper {
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 56.25%; // アスペクト比 16:9の場合の縦幅
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}