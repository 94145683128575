@use "../foundation/functions" as f;
@use "../foundation/variables" as v;

/*=======================================
  ページ制御系
=======================================*/
@mixin pc {
  @media (min-width: (v.$pc_min)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: (v.$tab_max)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: (v.$sp_max)) {
    @content;
  }
}
@mixin header_break {
  @media (max-width: (v.$header_break)) {
    @content;
  }
}
@mixin fv_height_break {
  @media (max-height: 500px) {
    @content;
  }
}
@mixin mq_hover_device {
  @media (any-hover: hover) {
    @content;
  }
}
@mixin mq_touch_device {
  @media (any-hover: none) {
    @content;
  }
}
//縦長
@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}
//横長
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}
@mixin tab_landscape {
  @media (orientation: landscape) and (max-width: (v.$tab_max)) {
    @content;
  }
}

body {
  position: relative;
  z-index: -1000;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  -webkit-text-size-adjust: 100%; //一部のモバイル端末で使われる、テキストの自動拡大アルゴリズムを制御
  font-family: v.$noto;
  font-size: 16px;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(40);
  overflow-y: scroll;
  background-color: #000;
  ::selection {
    color: v.$selection_text;
    background: v.$selection_bg;
  }
}
#root {
  position: relative;
  z-index: -100;
  &.modal_active {
    overflow: hidden;
    height: f.svh(100);
  }
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:link {
    color: inherit;
  }
  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
}
img {
  &::selection {
    color: v.$selection_text;
    background: rgb(255, 255, 255, 0.3);
  }
}
// 点線を生成
@mixin dashed_border($height: 1px, $dash: 3px, $spacing: 5px, $color: #aaa) {
  display: block;
  height: $height;
  position: relative;
  &::before {
    content: "";
    background-image: linear-gradient(
      to right,
      $color 0px,
      $color $dash,
      transparent $dash,
      transparent #{$dash + $spacing}
    );
    background-size: #{$dash + $spacing} $height;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
// テキストを省略
@mixin line_clamp($line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}
@mixin hv_opacity {
  transition: 0.18s;
  &:hover {
    opacity: 0.75;
  }
}
@mixin xd_cropped_line_height($font_size, $line_height) {
  $xd_lh: f.xd_line_height($font_size, $line_height);
  line-height: $xd_lh;
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$xd_lh}) * 0.5em);
    content: "";
  }
  &::after {
    display: block;
    width: 0;
    height: 0;
    margin-bottom: calc((1 - #{$xd_lh}) * 0.5em);
    content: "";
  }
}
@mixin fontawesome($type) {
  @if $type == fas {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_fas;
  } @else if $type == far {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_far;
  } @else if $type == fal {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_fal;
  } @else if $type == fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: $fontawesome_weight_fab;
  }
}
@mixin content_width($width: v.$pc_min) {
  max-width: calc($width + v.$body_pd + v.$body_pd);
  width: 100%;
  margin: 0 auto;
  padding: 0 v.$body_pd;
}
@mixin external_link_icon {
  position: relative;
  padding-right: 1.5em;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-image: url(../../img/common/icon_external.svg);
    background-repeat: no-repeat;
    width: 0.8em;
    height: 0.8em;
    bottom: 2px;
    right: 0.3em;
  }
}

/*=======================================
  アニメーションディレイ
=======================================*/
@mixin anim_delay($magnification: 0.05) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      animation-delay: #{$i * $magnification}s;
    }
  }
}
@mixin tran_delay($magnification: 0.05) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      transition-delay: #{$i * $magnification}s;
    }
  }
}
